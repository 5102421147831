import VehicleInfo from './vehicle-info'
import PhotoUpload from './photo-upload'
import CustomerDetail from './customer-detail'
import ThanksPage from './thanks-page'
import PrivacyPolicy from './privacy-policy'
import TermsAndConditions from './terms-and-conditions'
import PhotoGuide from './photo-guide'
import SellerAgreement from './seller-agreement'
import SellerFees from './seller-fees'

export default {
    VehicleInfo,
    PhotoUpload,
    CustomerDetail,
    ThanksPage,
    PrivacyPolicy,
    TermsAndConditions,
    PhotoGuide,
    SellerAgreement,
    SellerFees,
}
